import React from 'react'
import { withRouter, Link } from "react-router-dom"
import Albums from './Albums'
import AudioPlayer from '../AudioPlayer'

import { AlbumApi } from '../../api'

class Music extends React.Component {
	state = {
		albums: undefined,
		currnetAlbum: undefined
	}

	componentDidMount = async () => {
		const albums = await AlbumApi.getAll({ _sort: 'updatedAt:desc' })

		if (albums.data.length > 0) {
			this.setState({
				albums: albums.data
			}, () => this.chooseAlbum(albums.data[0]._id))
		}
	}

	getAlbumLink = album => '/albums/' + album._id
	getLyricsLink = (album, track) => {
		if (!track.lyrics) return

		return '/albums/' + album._id + '?track=' + track._id
	}

	playerLinks = {
		getAlbumLink: this.getAlbumLink,
		getLyricsLink: this.getLyricsLink
	}

	getAlbumById = id => this.state.albums ? this.state.albums.find(album => album._id === id) : null

	getPlayerForAlbum = albumId => {
		let album = this.getAlbumById(albumId)

		if (album) {
			return (
				<div className="music__player">
					<AudioPlayer
						album={album}
						url_prefix={process.env.SERVER_URL}
						links={this.playerLinks}
						breakpoint={580}
					/>
				</div>
			)
		}
	}

	chooseAlbum = albumId => {
		const album = this.getPlayerForAlbum(albumId)
		this.setState({ currnetAlbum: album })
	}

	render() {
		return (
			<div id="music" className="block-holder music">
				<section className="container">
					<h2 className="container__title">Music</h2>
					<div className="container__content">
						{this.state.currnetAlbum && this.state.currnetAlbum}
					</div>
					<Albums chooseAlbum={this.chooseAlbum} />
				</section>
			</div>
		)
	}
}

export default withRouter(Music)
