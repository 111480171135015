import React from 'react'
import { withRouter, Link } from "react-router-dom"
import { HashLink } from 'react-router-hash-link'
import AnchorLink from 'react-anchor-link-smooth-scroll'

class Menu extends React.Component {
	state = {
		isLoggedIn: false
	}

	componentDidMount = async () => {
		try {
			const user = await UserApi.get()
			this.setState({ isLoggedIn: true })
		} catch(e) { }
	}

	offMenuToggle = () => {
		if(this.menuToggle) {
			this.menuToggle.checked = false
		}
	}

	isMainPage = () => window.location.pathname === '/'

	createLink = (tag, label) => {
		if(this.isMainPage()) {
			return <AnchorLink href={tag}>{label}</AnchorLink>
		} else {
			return <HashLink to={'/' + tag}>{label}</HashLink>
		}
	}

	render() {
		return(
			<header className={
				'main-menu' +
				(this.props.stickMenu ? ' main-menu--sticky' : '')
			}>
				<div className={this.props.stickMenu ? 'main-menu__bg main-menu__bg--sticky' : 'main-menu__bg'}></div>
				<div className={this.props.stickMenu ? 'main-menu__links' : 'main-menu__links main-menu__links--top'}>
					<div className="main-menu__links__logo">
						{this.isMainPage() ?
							<AnchorLink href='#hero'>TOMMY SEA</AnchorLink> :
							<Link to="/">TOMMY SEA</Link>
						}
					</div>
					<div className="main-menu__links__menu">
						<input type="checkbox" id="main-menu__toggle__input" ref={el => this.menuToggle = el}/>
						<label className="main-menu__toggle" htmlFor="main-menu__toggle__input">
							<a>menu</a>
						</label>

						<div className="main-menu__nav--bg"></div>

						<nav className="main-menu__nav">
							<ul className="main-menu__nav__links main-menu__nav__links--pages">
								<li onClick={this.offMenuToggle}>
									<Link to="/">Home</Link>
								</li>
								<li onClick={this.offMenuToggle}>
									<Link to="/news">News</Link>
								</li>
							</ul>

							{this.isMainPage() &&
								<ul className="main-menu__nav__links main-menu__nav__links--tags">
									<li onClick={this.offMenuToggle}>
										{this.createLink('#about', 'About')}
									</li>
									<li onClick={this.offMenuToggle}>
										{this.createLink('#music', 'Music')}
									</li>
									<li onClick={this.offMenuToggle}>
										{this.createLink('#video', 'Video')}
									</li>
									<li onClick={this.offMenuToggle}>
										{this.createLink('#news', 'News')}
									</li>
									<li onClick={this.offMenuToggle}>
										{this.createLink('#gallery', 'Gallery')}
									</li>
									<li onClick={this.offMenuToggle}>
										{this.createLink('#contact_us', 'Contact Us')}
									</li>
								</ul>
							}
						</nav>
					</div>
				</div>
			</header>
		)
	}
}

export default withRouter(Menu)
