import React from 'react'

const Lines = () => (
	<div className="lines">
		<div className="lines__line"></div>
		<div className="lines__line"></div>
		<div className="lines__line"></div>
		<div className="lines__line"></div>
		<div className="lines__line"></div>
	</div>
)

export default Lines
