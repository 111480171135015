import React, { Component } from 'react'
import throttle from 'lodash/throttle'
import has from 'lodash/has'
import classnames from 'classnames'

import Slide from './Slide'

export default class Slider extends Component {
  static defaultProps = {
    slidesPerPage: 1,
    slidesPerScroll: 1,
    animationSpeed: 1000
  }

  constructor(props) {
    super(props)

    this.state = {
      currentSlide: props.value ? props.value : 0,
      carouselWidth: 0,
      carouselHeight: 0,
      windowWidth: 0,
      transitionEnabled: false
    }
  }

  /* ========== initial handlers and positioning setup ========== */
  componentDidMount() {
    // adding listener to remove transition when animation finished
    this.trackRef && this.trackRef.addEventListener('transitionend', this.onTransitionEnd)

    // setting size of a carousel in state
    window.addEventListener('resize', this.onResize)
    this.onResize()

    // setting size of a carousel in state based on styling
    window.addEventListener('load', this.onResize)
  }

  componentDidUpdate(prevProps) {
    const newValue = this.getNewSlideIndex(prevProps)

    if(newValue !== 0) {
      this.setState((prevState) => ({
        transitionEnabled: true,
        currentSlide: this.clamp(prevState.currentSlide + newValue * this.getProp('slidesPerScroll'))
      }))
    }

    this.onResize()
  }

  componentWillUnmount() {
    this.trackRef && this.trackRef.removeEventListener('transitionend', this.onTransitionEnd)

    window.removeEventListener('resize', this.onResize)
		window.removeEventListener('load', this.onResize)
  }
	
  /* ========== tools ========== */

  /**
   * Returns the value of a prop based on the current window width and breakpoints provided
   * @param {string} propName name of the prop you want to get
   * @param {object} customProps props object (used e.g. when you want to
   * get prop from prevProps object instead of this.props)
   * @return {any} props value
   */
  getProp = (propName, customProps = null) => {
    const props = customProps || this.props
		let activeBreakpoint = null
		
    if(props.breakpoints) {
      const windowWidth = this.state.windowWidth
      const resolutions = Object.keys(props.breakpoints)
      resolutions.forEach(resolutionString => {
				const resolution = parseInt(resolutionString)
				
        if(windowWidth <= resolution) {
          if(!activeBreakpoint || activeBreakpoint > resolution) {
            activeBreakpoint = resolution
          }
        }
      })
		}
		
    if(activeBreakpoint) {
      if(has(props.breakpoints[activeBreakpoint], propName)) {
        return props.breakpoints[activeBreakpoint][propName]
      }
		}
		
    return props[propName]
  }

  getNewSlideIndex = prevProps => {
    if(!this.props.value) return 0
    return  this.props.value - prevProps.value
  }

  getChildren = () => {
    if(!this.props.children) {
      if(this.props.slides) {
        return this.props.slides
			}
			
      return []
    }
    if(Array.isArray(this.props.children)) {
      return this.props.children
		}
		
    return [this.props.children]
  }

  /* event handlers */
  /**
   * Handler setting the carouselWidth value in state (used to set proper width of track and slides)
   * throttled to improve performance
   * @type {Function}
   */
   onResize = throttle(() => {
     if(!this.node && !this.trackRef) {
       return
     }

     this.setState(() => ({
       carouselWidth: this.node.scrollWidth,
       carouselHeight: this.trackRef.scrollHeight,
       windowWidth: this.node.scrollWidth,
       windowWidth: window.innerWidth,
     }))
   }, 300)

  /**
   * Handler setting transitionEnabled value in state to false after transition animation ends
   */
  onTransitionEnd = () => {
    this.setState(() => ({
      transitionEnabled: false
    }))
  }

  /* ========== control ========== */
  /**
   * Clamps number between 0 and last slide index.
   * @param {number} value to be clamped
   * @return {number} new value
   */
  clamp = value => {
    const maxValue = this.getChildren().length - this.getProp('slidesPerPage')

    if(value < 0) {
      return 0
		}
    
    if(value > maxValue) {
      return maxValue
    }

    return value
  }

  /**
   * Returns the current slide index (from either props or internal state)
   * @return {number} index
   */
  getActiveSlideIndex = () => this.clamp(this.props.value)

  /**
   * Calculates width of a single slide in a carousel
   * @return {number} width of a slide in px
   */
  getCarouselElementWidth = () => this.props.itemWidth || this.state.carouselWidth / this.getProp('slidesPerPage')

   /**
   * Height of carousel track
   * @return {number} height of a carousel in px
   */
  getCarouselElementHeight = () => this.state.carouselHeight
  
  /**
   * Calculates offset in pixels to be applied to Track element in order to
   * show current slide correctly (centered or aligned to the left)
   * @return {number} offset in px
   */
  getTransformOffset = () => {
    const elementWidth = this.getCarouselElementWidth()
    // const currentValue = this.getActiveSlideIndex()
    const currentValue = this.state.currentSlide

    return - currentValue * elementWidth
  }


  /* ========== rendering ========== */
  renderSliderItems = () => {
    const transformOffset = this.getTransformOffset()
		const slides = this.getChildren()
		
    const trackWidth = this.state.carouselWidth * slides.length
    const animationSpeed = this.getProp('animationSpeed')
		const transitionEnabled = this.state.transitionEnabled
    
    const trackStyles = {
      width: `${trackWidth}px`,
      transform: `translateX(${transformOffset}px)`,
      transitionDuration: transitionEnabled ? `${animationSpeed}ms, ${animationSpeed}ms` : null,
    }

    return (
      <div
        className="BrainhubCarousel__trackContainer"
        style={{
          height: this.getCarouselElementHeight() + 'px'
        }}
      >
        <div
          className={classnames(
            'BrainhubCarousel__track',
            {
              'BrainhubCarousel__track--transition': transitionEnabled
            },
          )}
          style={trackStyles}
          ref={el => this.trackRef = el}
        >
          {slides.map((carouselItem, index) => (
            <Slide
              key={index}
              currentSlideIndex={this.getActiveSlideIndex()}
              index={index}
              width={this.getCarouselElementWidth()}
            >
              {carouselItem}
            </Slide>
          ))}
        </div>
      </div>
    )
  }

  render = () => (
		<div
				className={classnames('BrainhubCarousel', this.getProp('className'))}
				ref={el => this.node = el}
			>
				{this.renderSliderItems()}
		</div>
	)
}
