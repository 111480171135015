import React from 'react'
import { Waypoint } from 'react-waypoint'

import { AlbumApi } from '../../api'

import Lines from '../helpers/Lines'
import Menu from '../Menu'
import Album from './Album'
import ContactUs from '../ContactUs'
import NotFoundPage from '../NotFoundPage'

export default class AlbumPage extends React.Component {
	state = {
		stickMenu: false,
		notFound: false,
		isLoading: false,
		album: undefined,
		chosenLyrics: null
	}

	handleStickMenuWaypointEnter = () => {
		this.setState(() => ({ stickMenu: false }))
	}

	handleStickMenuWaypointLeave = () => {
		this.setState(() => ({ stickMenu: true }))
	}

	componentDidMount = async () => {
		this.setState({ isLoading: true })

		try {
			const id = this.props.match.params.id
			const album = await AlbumApi.getById(id)

			const urlParams = new URLSearchParams(this.props.location.search)
			const chosenLyrics = urlParams.get('track')
			
			this.setState({
				album: album.data,
				isLoading: false,
				chosenLyrics
			})
		} catch(e) {
			this.setState({
				notFound: true,
				isLoading: false,
			})
		}
	}

	render() {
		if(this.state.notFound) {
			return <NotFoundPage />
		}

		return(
			<div className="album-page page">
				<Lines />
				<Menu stickMenu={this.state.stickMenu} />
				<Waypoint
					onEnter={this.handleStickMenuWaypointEnter}
					onLeave={this.handleStickMenuWaypointLeave}
				/>
				{this.state.album &&
					<Album album={this.state.album} chosenLyrics={this.state.chosenLyrics} />
				}
				<div className="connection-line connection-line--last"></div>
				<ContactUs />
			</div>
		)
	}
}
