import React from 'react'
import Slider from '../helpers/Slider/Slider'
import {
	IoIosArrowRoundForward,
	IoIosArrowRoundBack
} from "react-icons/io"

import { AlbumApi } from '../../api'

export default class Albums extends React.Component {
	state = {
		isLoading: false,
		currentSlide: 0,
		albums: []
	}

	componentDidMount = async () => {
		this.setState({ isLoading: true })

		try {
			const albums = await AlbumApi.getAll({
				_sort: 'updatedAt:desc'
			})

			this.setState({
				albums: albums.data,
				isLoading: false,
			})
		} catch (e) {
			console.log(e)
		}
	}

	getSlides = () => {
		if (this.state.albums.length) {
			return this.state.albums.map(
				album => this.createAlbum(album)
			)
		} else return []
	}

	createAlbum = (album) => (
		<a className="albums__album" onClick={() => this.props.chooseAlbum(album._id)} key={album._id}>
			<div className="albums__album__block">
				<img className="albums__album__img" src={process.env.SERVER_URL + album.coverImage.url} alt="" />
				<div className="albums__album__brief">
					<div className="albums__album__description">
						{album.albumName}
					</div>
				</div>
			</div>
		</a>
	)

	prevSlide = () => {
		this.setState((prevState) => ({ currentSlide: prevState.currentSlide - 1 }))
	}

	nextSlide = () => {
		this.setState((prevState) => ({ currentSlide: prevState.currentSlide + 1 }))
	}

	render() {
		const carouselSettings = {
			slides: this.getSlides(),
			value: this.state.currentSlide,
			slidesPerPage: 3,
			slidesPerScroll: 2,
			breakpoints: {
				1100: {
					slidesPerPage: 2,
					slidesPerScroll: 1
				},
				600: {
					slidesPerPage: 1,
					slidesPerScroll: 1
				},
			}
		}

		return (
			<div className="albums">
				<Slider {...carouselSettings} />
				<div className="albums__controls">
					<div className="albums__controls__arrows">
						<a onClick={this.prevSlide}><IoIosArrowRoundBack /></a>
						<a onClick={this.nextSlide}><IoIosArrowRoundForward /></a>
					</div>
				</div>
			</div>
		)
	}
}
