import axios from 'axios'

const api = axios.create({
	baseURL: process.env.SERVER_URL,
	withCredentials: false
})

export const HeaderTextBlockApi = {
	get: () => api.get('/header-text-block/')
}

export const AboutApi = {
	get: () => api.get('/about/')
}

export const NewsApi = {
	getAll: params => api.get('/articles', { params }),
	getById: id => api.get('/articles/' + id)
}

export const AlbumApi = {
	getAll: params => api.get('/albums', { params }),
	getById: id => api.get('/albums/' + id)
}

export const TrackApi = {
	getById: id => api.get('/tracks/' + id)
}

export const VideoApi = {
	getAll: params => api.get('/videos', { params }),
	getById: id => api.get('/videos/' + id)
}

export const ImageApi = {
	getAll: params => api.get('/images', { params }),
	getById: id => api.get('/images/' + id)
}
