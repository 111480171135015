import React from "react";
import { Link } from "react-router-dom";
import memoize from "memoize-one";
const moment = require("moment");
import { Howl } from "howler";
import { IconContext } from "react-icons";
import {
  TiMediaPlayOutline,
  TiMediaFastForwardOutline,
  TiMediaPauseOutline,
} from "react-icons/ti";

import payPalBtn from "./payPalBtn";

/*
	props.album = {
		coverImage: {
			url: './img.jpg'
		},
		artist: 'Artist Name',
		albumName: 'Ambum',
		footer: '',
		releaseDate: new Date('10/4/2018'),
		copyright: '',
		mediaIcons: [
			{
				icon: <FaApple />,
				text: 'MUSIC',
				alt: 'Apple Music',
				link: 'https://react-icons.netlify.com/#/icons/fa'
			},
		],
		playlist: [
			{
				index: 1,
				name: 'Song One',
				file: {
					url: 'song1.mp3'
				}
			},
			{
				index: 2,
				name: 'Song Two',
				file: {
					url: 'song2.mp3'
				}
			}
		]
	}
*/

export default class AudioPlayer extends React.Component {
  playerStatus = {
    INITIALIZED: "initialized",
    PLAYING: "playing",
    STOPPED: "stopped",
    PAUSED: "paused",
    init(global_this) {
      this.global = global_this;
    },
    isInitialized() {
      return this.global.state.status === this.INITIALIZED;
    },
    isPlaying() {
      return this.global.state.status === this.PLAYING;
    },
    isStopped() {
      return this.global.state.status === this.STOPPED;
    },
    isPaused() {
      return this.global.state.status === this.PAUSED;
    },
  };

  constructor(props) {
    super(props);

    this.playerStatus.init(this);

    this.state = {
      tracklist: [],
      currentTrack: undefined,
      status: this.playerStatus.INITIALIZED,
      intervalId: undefined,
      remainingTime: "0:00",
      progressBarWidth: 0,
      updatingTime: false,
      tracklistScrolled: false,
      isMobile: false,
    };
  }

  componentDidMount = () => {
    this.updatePlaylist(this.props.album._id);

    window.addEventListener("resize", this.onResize);
    this.onResize();
  };

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.onResize);
    clearInterval(this.state.intervalId);
  };

  componentDidUpdate = () => {
    this.updatePlaylist(this.props.album._id);
  };

  updatePlaylist = memoize((albumId) => {
    if (this.state.currentTrack) {
      this.state.currentTrack.player.stop();
    }

    this.setState({
      tracklist: this.initSongs(),
      currentTrack: undefined,
      status: this.playerStatus.INITIALIZED,
    });
  });

  onResize = () => {
    if (!this.props.breakpoint) {
      return;
    }

    const isMobile = window.innerWidth <= this.props.breakpoint;
    if (this.state.isMobile === isMobile) {
      return;
    }

    this.setState({ isMobile });
  };

  initSongs = () => {
    return this.props.album.tracks
      .sort((a, b) => a.index - b.index)
      .map((track, i) => {
        return {
          ...track,
          player: new Howl({
            src: [].concat(this.props.url_prefix + track.file.url || []),
            format: ["mp3"],
            onplay: () => {
              const intervalId = setInterval(() => {
                if (!this.state.updatingTime && this.state.currentTrack) {
                  this.setState({
                    remainingTime: this.formatTime(
                      this.state.currentTrack.player.duration() -
                        this.state.currentTrack.player.seek()
                    ),
                    progressBarWidth: this.progressBarWidth(),
                  });
                }
              }, 50);

              this.setState(() => ({
                intervalId: intervalId,
              }));
            },
            onpause: () => {
              clearInterval(this.state.intervalId);
            },
            onstop: () => {
              this.setState({
                progressBarWidth: 0,
              });
              clearInterval(this.state.intervalId);
            },
            onend: () => {
              this.setState({
                progressBarWidth: 0,
              });
              clearInterval(this.state.intervalId);
              this.playNext(i);
            },
          }),
        };
      });
  };

  formatTime(secs) {
    secs = Math.round(secs);

    var minutes = Math.floor(secs / 60) || 0;
    var seconds = secs - minutes * 60 || 0;

    return "-" + minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
  }

  play = (track) => {
    if (track && !this.state.currentTrack) {
      this.setState(
        () => ({
          currentTrack: track,
          remainingTime: this.formatTime(track.player.duration()),
          status: this.playerStatus.PLAYING,
        }),
        () => track.player.play()
      );
    } else if (track && this.state.currentTrack) {
      this.state.currentTrack.player.stop();
      this.setState(
        {
          currentTrack: undefined,
          status: this.playerStatus.STOPED,
        },
        () => this.play(track)
      );
    }
  };

  playNext = (trackIndex) => {
    if (trackIndex >= 0 && trackIndex < this.state.tracklist.length - 1) {
      this.play(this.state.tracklist[trackIndex + 1]);
    } else {
      this.setState({
        currentTrack: undefined,
        status: this.playerStatus.STOPED,
      });
    }
  };

  seekToEnd = () => {
    if (this.state.currentTrack) {
      if (this.playerStatus.PAUSED) {
        this.state.currentTrack.player.play();
      }
      this.state.currentTrack.player.seek(
        this.state.currentTrack.player.duration()
      );
    }
  };

  pauseToggle = () => {
    if (this.state.currentTrack && this.state.currentTrack.player.playing()) {
      this.state.currentTrack.player.pause();
      this.setState(() => ({
        status: this.playerStatus.PAUSED,
      }));
    } else if (this.state.currentTrack && !this.playerStatus.isPlaying()) {
      const track = this.state.currentTrack;
      this.setState(
        {
          currentTrack: undefined,
        },
        () => this.play(track)
      );
    } else if (this.state.tracklist) {
      this.play(this.state.tracklist[0]);
    }
  };

  isTrackPlaying = (track) => {
    if (this.state.currentTrack && this.playerStatus.isPlaying()) {
      if (this.state.currentTrack == track) {
        return true;
      }
    }

    return false;
  };

  isTrackPaused = (track) => {
    if (this.state.currentTrack && this.playerStatus.isPaused()) {
      if (this.state.currentTrack == track) {
        return true;
      }
    }

    return false;
  };

  getDate = (date) => moment(date, moment.ISO_8601).format("MMMM D, YYYY");

  playerMediaLinks = () => (
    <div className="audio-palyer__media-links">
      {this.props.album.mediaIcons.map((icon, i) => (
        <a
          className="audio-palyer__media-link"
          href={icon.link}
          alt={icon.alt}
          target="_blank"
          key={i}
        >
          {icon.icon && (
            <span className="audio-palyer__media-link__icon">
              <IconContext.Provider value={{ className: "player-icon" }}>
                {icon.icon}
              </IconContext.Provider>
            </span>
          )}
          {icon.text && (
            <span className="audio-palyer__media-link__text">{icon.text}</span>
          )}
        </a>
      ))}
    </div>
  );

  playerCover = () => (
    <div className="audio-palyer__cover">
      <div className="audio-palyer__cover__img" onClick={this.pauseToggle}>
        <img
          src={this.props.url_prefix + this.props.album.coverImage.url}
          alt={this.props.album.albumName + " cover"}
        />

        <IconContext.Provider
          value={{ className: "player-icon player-icon--play" }}
        >
          <TiMediaPlayOutline />
        </IconContext.Provider>
        <IconContext.Provider
          value={{ className: "player-icon player-icon--pause" }}
        >
          <TiMediaPauseOutline />
        </IconContext.Provider>
      </div>
      <div className="audio-palyer__cover__amount">
        {this.state.tracklist.length} Songs
      </div>
      {this.props.album.cdPrice && this.props.album.cdPrice !== 0 && (
        <div className="audio-palyer__cover__paypal paypal-btns">
          {payPalBtn(this.props.album.cdPrice, false)}
        </div>
      )}
    </div>
  );

  playerHeader = () => {
    let albumNameLink = undefined;
    if (this.props.links && this.props.links.getAlbumLink) {
      albumNameLink = this.props.links.getAlbumLink(this.props.album);
    }

    return (
      <div>
        <div className="audio-palyer__album-name">
          {albumNameLink ? (
            <Link to={albumNameLink}>{this.props.album.albumName}</Link>
          ) : (
            this.props.album.albumName
          )}
        </div>
        {this.state.currentTrack ? (
          <div className="audio-palyer__artist-name audio-palyer__track-name">
            {this.props.album.artist + " — " + this.state.currentTrack.name}
          </div>
        ) : (
          <div className="audio-palyer__artist-name">
            {this.props.album.artist}
          </div>
        )}
      </div>
    );
  };

  playerControls = () => (
    <div className="audio-palyer__content__controls">
      <a className="progress-icons" onClick={this.seekToEnd}>
        <IconContext.Provider
          value={{
            className: "player-icon__small player-icon__small--forward",
          }}
        >
          <TiMediaFastForwardOutline />
        </IconContext.Provider>
      </a>
      <div className="progress-bar">
        <input
          className="progress-bar__bg"
          type="range"
          step="any"
          min="0"
          max="100"
          value={this.state.progressBarWidth}
          onChange={this.onProgressBarWidthChange}
          onMouseDown={this.onProgressBarMouseDown}
          onMouseUp={this.onProgressBarMouseUp}
        />
        <div
          className="progress-bar__fg"
          style={{ width: this.state.progressBarWidth + "%" }}
        ></div>
      </div>
      <div className="track-timer">{this.state.remainingTime}</div>
    </div>
  );

  onProgressBarWidthChange = (e) => {
    this.setState({
      remainingTime: this.formatTime(
        this.state.currentTrack.player.duration() -
          (e.target.value / 100) * this.state.currentTrack.player.duration()
      ),
      progressBarWidth: e.target.value,
    });
  };

  onProgressBarMouseDown = (e) => {
    this.setState(() => ({
      updatingTime: true,
    }));
  };

  onProgressBarMouseUp = (e) => {
    this.setState(() => ({
      updatingTime: false,
    }));

    if (this.state.currentTrack) {
      this.state.currentTrack.player.seek(
        (e.target.value / 100) * this.state.currentTrack.player.duration()
      );
    }
  };

  progressBarWidth = () => {
    if (this.state.currentTrack) {
      const width =
        this.state.currentTrack.player.seek() /
        (this.state.currentTrack.player.duration() / 100);

      return width;
    }

    return 0;
  };

  listSongs = () => (
    <ul className="tracklist__tracks">
      {this.state.tracklist.map((track, i) => {
        let trackLyricsLink = undefined;
        if (this.props.links && this.props.links.getLyricsLink) {
          trackLyricsLink = this.props.links.getLyricsLink(
            this.props.album,
            track
          );
        }

        return (
          <li
            className={
              "tracklist__track " +
              (this.isTrackPlaying(track) ? "track-playing " : "") +
              (this.isTrackPaused(track) ? "track-paused " : "")
            }
            key={track._id}
            onClick={() => this.play(track)}
          >
            {!this.isTrackPlaying(track) && !this.isTrackPaused(track) && (
              <div className="tracklist__track__index">{i + 1}</div>
            )}
            {(this.isTrackPlaying(track) || this.isTrackPaused(track)) && (
              <div
                className={"bars " + (this.isTrackPlaying(track) ? "wavy" : "")}
              >
                <div className="bar one"></div>
                <div className="bar two"></div>
                <div className="bar three"></div>
                <div className="bar four"></div>
              </div>
            )}
            <div className="tracklist__track__name">{track.name}</div>
            {trackLyricsLink && (
              <div className="tracklist__track__links">
                <Link to={trackLyricsLink}>Lyrics</Link>
              </div>
            )}
          </li>
        );
      })}
    </ul>
  );

  tracklistFooter = () => (
    <div className="tracklist__footer">
      {this.props.album.footer && <div>{this.props.album.footer}</div>}
      {this.props.album.releaseDate && (
        <div>Released: {this.getDate(this.props.album.releaseDate)}</div>
      )}
      {this.props.album.copyright && <div>{this.props.album.copyright}</div>}
    </div>
  );

  handleScroll = (e) => {
    let element = e.target;
    if (element.scrollTop > 0) {
      this.setState({
        tracklistScrolled: true,
      });
    } else {
      this.setState({
        tracklistScrolled: false,
      });
    }
  };

  getNormalPlayer = () => (
    <div
      className={
        "audio-palyer  audio-palyer--normal" +
        (this.playerStatus.isPlaying() ? " playing" : "") +
        (this.playerStatus.isPaused() ? " paused" : "")
      }
    >
      {this.playerMediaLinks()}
      <div className="audio-palyer__body">
        {this.playerCover()}
        <div className="audio-palyer__content">
          {this.playerHeader()}
          {this.playerControls()}
          <div
            className={
              "audio-palyer__tracklist " +
              (this.state.tracklistScrolled ? "top-gradient-visible" : "")
            }
            onScroll={this.handleScroll}
          >
            {this.listSongs()}
            {this.tracklistFooter()}
          </div>
        </div>
      </div>
    </div>
  );

  getMobilePlayer = () => (
    <div
      className={
        "audio-palyer audio-palyer--mobile" +
        (this.playerStatus.isPlaying() ? " playing" : "") +
        (this.playerStatus.isPaused() ? " paused" : "")
      }
    >
      {this.playerMediaLinks()}
      <div className="audio-palyer__body">
        <div className="audio-palyer__content">
          <div className="audio-palyer__content__header__wrapper">
            {this.playerCover()}
            <div className="audio-palyer__content__controls__wrapper">
              {this.playerHeader()}
              {this.playerControls()}
            </div>
          </div>
          {this.props.album.cdPrice && this.props.album.cdPrice !== 0 && (
            <div className="audio-palyer__cover__paypal--wide paypal-btns">
              {payPalBtn(this.props.album.cdPrice, false)}
            </div>
          )}
          <div
            className={
              "audio-palyer__tracklist " +
              (this.state.tracklistScrolled ? "top-gradient-visible" : "")
            }
            onScroll={this.handleScroll}
          >
            {this.listSongs()}
            {this.tracklistFooter()}
          </div>
        </div>
      </div>
    </div>
  );

  render() {
    if (this.state.isMobile) {
      return this.getMobilePlayer();
    } else {
      return this.getNormalPlayer();
    }
  }
}
