import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import MainPage from '../components/MainPage/MainPage'
import AllNewsPage from '../components/AllNewsPage/AllNewsPage'
import NewsArticlePage from '../components/NewsArticlePage/NewsArticlePage'
import AlbumPage from '../components/AlbumPage/AlbumPage'

import NotFoundPage from '../components/NotFoundPage'

const AppRouter = () => (
	<BrowserRouter>
		<Switch>
			<Route path="/news/:id" component={NewsArticlePage} />
			<Route path="/news" exact component={AllNewsPage} />
			<Route path="/albums/:id" component={AlbumPage} />

			<Route path="/" exact component={MainPage} />
			<Route component={NotFoundPage} />
		</Switch>
	</BrowserRouter>
)

export default AppRouter
