import React from 'react'
const moment = require('moment')

import Markdown from '../helpers/Markdown'

export default class NewsArticle extends React.Component {
	getDate = date => moment(date, moment.ISO_8601).format("MMM Do YYYY")

	createNews = () => (
		<div className="article-preview">
			<div className="article-preview__img">
				<img src={process.env.SERVER_URL + process.env.SERVER_PORT + this.props.article.img.url} alt="Article Picture" />
			</div>
			<div className="article-preview__body">
				<div className="article-preview__date">
					{this.getDate(this.props.article.updatedAt)}
				</div>
				<div className="article-preview__description">
					<Markdown>{this.props.article.content}</Markdown>
				</div>
			</div>
		</div>
	)

	render() {
		return (
			<div id="news-article" className="block-holder news-article">
				<section className="container">
					<h2 className="container__title">{this.props.article.title}</h2>
					<div className="container__content">
						{this.createNews()}
					</div>
				</section>
			</div>
		)
	}
}
