import React from 'react'
import { Link } from "react-router-dom"
import Slider from '../helpers/Slider/Slider'
import {
	IoIosArrowRoundForward,
	IoIosArrowRoundBack
} from "react-icons/io"

import { NewsApi } from '../../api'

export default class News extends React.Component {
	state = {
		isLoading: false,
		currentSlide: 0,
		news: []
	}

	componentDidMount = async () => {
		this.setState({ isLoading: true })

		try {
			const news = await NewsApi.getAll({
				_sort: 'createdAt:desc',
				_limit: 4
			})

			this.setState({
				news: news.data,
				isLoading: false,
			})
		} catch (e) {
			console.log(e)
		}
	}

	getSlides = () => {
		if (this.state.news.length) {
			return this.state.news.map(
				article => this.createArticle(article)
			)
		} else return []
	}

	createArticle = (article) => (
		<Link to={'/news/' + article._id} className="news__article" key={article._id}>
			<div className="news__article__block">
				<img className="news__article__img" src={process.env.SERVER_URL + article.img.url} alt="" />
				<div className="news__article__brief">
					{article.description &&
						<div className="news__article__description">
							{article.description}
						</div>
					}
				</div>
			</div>
			<h4 className="news__article__title">
				{article.title}
			</h4>
		</Link>
	)

	prevSlide = () => {
		this.setState((prevState) => ({ currentSlide: prevState.currentSlide - 1 }))
	}

	nextSlide = () => {
		this.setState((prevState) => ({ currentSlide: prevState.currentSlide + 1 }))
	}

	render() {
		const carouselSettings = {
			slides: this.getSlides(),
			value: this.state.currentSlide,
			slidesPerPage: 2,
			slidesPerScroll: 2,
			breakpoints: {
				800: {
					slidesPerPage: 1,
					slidesPerScroll: 1
				},
			}
		}

		return (
			<div id="news" className="block-holder news">
				<section className="container">
					<h2 className="container__title">News</h2>
					<div className="container__content">
						<Slider {...carouselSettings} />
						<div className="news__controls">
							<div className="news__controls__arrows">
								<a onClick={this.prevSlide}><IoIosArrowRoundBack /></a>
								<a onClick={this.nextSlide}><IoIosArrowRoundForward /></a>
							</div>

							<h3 className="news__controls__all">
								<Link to="/news">All Articles</Link>
							</h3>
						</div>
					</div>
				</section>
			</div>
		)
	}
}
