import React from 'react'
import {
	FiChevronUp,
	FiChevronDown
} from "react-icons/fi"

import { AboutApi } from '../../api'

import Expander from '../helpers/Expander'
import Markdown from '../helpers/Markdown'

export default class About extends React.Component {
	state = {
		isExpanded: false,
		about: '',
		pictureUrl: '',
		isLoading: false
	}

	componentDidMount = async () => {
		this.setState({ isLoading: true })

		try {
			const text = await AboutApi.get()

			this.setState({
				about: text.data.content,
				pictureUrl: process.env.SERVER_URL + text.data.picture.url
			})
		} catch (e) {
			console.log(e)
		}
	}

	toggleText = (e) => {
		e.preventDefault()

		this.setState((prevState) => ({ isExpanded: !prevState.isExpanded }))
	}

	render() {
		return (
			<div id="about" className="block-holder about">
				<div className="block-holder__bg block-holder__bg--right"></div>
				<section className="container">
					<h2 className="container__title">About</h2>
					<div className="about__picture">
						<div className="about__picture__overlay"></div>
						<img src={this.state.pictureUrl} alt="About picture" />
					</div>
					<div className="container__content">
						<Expander
							heightCollapsed={500}
							duration={300}
							isExpanded={this.state.isExpanded}
							breakpoints={{
								1020: {
									heightCollapsed: 250
								}
							}}
						>
							<div>
								<Markdown>{this.state.about}</Markdown>
							</div>
						</Expander>
						<div className="read-more__button">
							<a onClick={this.toggleText}>
								{this.state.isExpanded &&
									<FiChevronUp />
								}
								{!this.state.isExpanded &&
									<FiChevronDown />
								}
							</a>
						</div>
					</div>
				</section>
			</div>
		)
	}
}
