import React from 'react'
import { withRouter, Link } from "react-router-dom"
const moment = require('moment')

import { NewsApi } from '../../api'

class AllNews extends React.Component {
	state = {
		isLoading: false,
		articles: []
	}

	componentDidMount = async () => {
		this.setState({ isLoading: true })

		try {
			const articles = await NewsApi.getAll({
				_sort: 'createdAt:desc',
			})

			this.setState({
				articles: articles.data,
				isLoading: false,
			})
		} catch (e) {
			console.log(e)
		}
	}

	getDate = date => moment(date, moment.ISO_8601).format("MMM Do YYYY")

	createNews = (article) => (
		<div className="article-preview" key={article._id}>
			<div className="article-preview__img">
				<Link to={this.props.location.pathname + '/' + article._id}>
					<img src={process.env.SERVER_URL + article.img.url} alt="Article Picture" />
				</Link>
			</div>
			<div className="article-preview__body">
				<div className="article-preview__header">
					<h3 className="article-preview__title">
						<Link to={this.props.location.pathname + '/' + article._id}>
							{article.title}
						</Link>
					</h3>
					<div className="article-preview__date">
						{this.getDate(article.updatedAt)}
					</div>
				</div>
				<div className="article-preview__description">
					{article.description}
				</div>
				<Link to={this.props.location.pathname + '/' + article._id} className="button article-preview__button" href="">
					Read More
				</Link>
			</div>
		</div>
	)

	render() {
		return (
			<div id="all-news" className="block-holder all-news">
				<section className="container">
					<h2 className="container__title">News</h2>
					<div className="container__content">
						{this.state.articles.length > 0 && this.state.articles.map(
							article => this.createNews(article)
						)}
					</div>
				</section>
			</div>
		)
	}
}

export default withRouter(AllNews)
