import React from 'react'

export default class AllNewsHeader extends React.Component {
	render() {
		return(
			<div id="all-news-header" className="block-holder all-news-header">
				<div className="all-news-header__bg">
					<img src="/img/bg-hero.png" alt=""/>
				</div>
				<section>
					<div className="hero__text-block">
						<h4>The eclectic sounds of</h4>
						<h1 className="hero__text-block__name">TOMMY SEA</h1>
						<p className="hero__text-block__description">
							I'm an independent songwriter who has assembled top talent to record my music.  I try to create clever melodies, intriguing instrument combinations while providing a balanced uplifting yet realistic presentation.
						</p>
					</div>
				</section>
			</div>
		)
	}
}
