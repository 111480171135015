import React from 'react'
import { Link } from 'react-router-dom'

const NotFoundPage = () => (
	<div className="page_not_found ">
		<div className="error-container">
			<span>4</span>
			<span><span className="screen-reader-text">0</span></span>
			<span>4</span>
		</div>
		<h1>Page Not Found</h1>
		<div className="link-container">
			<Link to="/" className="button">Go Home</Link>
		</div>
	</div>
)

export default NotFoundPage
