import React from 'react'
import { Waypoint } from 'react-waypoint'

import Lines from '../helpers/Lines'
import Menu from '../Menu'
import AllNewsHeader from './AllNewsHeader'
import AllNews from './AllNews'
import ContactUs from '../ContactUs'

export default class AllNewsPage extends React.Component {
	state = {
		stickMenu: false
	}

	handleStickMenuWaypointEnter = () => {
		this.setState(() => ({ stickMenu: false }))
	}

	handleStickMenuWaypointLeave = () => {
		this.setState(() => ({ stickMenu: true }))
	}

	render() {
		return(
			<div className="news-page page">
				<Lines />
				<Menu stickMenu={this.state.stickMenu} />
				<AllNewsHeader />
				<Waypoint
					onEnter={this.handleStickMenuWaypointEnter}
					onLeave={this.handleStickMenuWaypointLeave}
				/>
				<AllNews />
				<div className="connection-line connection-line--last"></div>
				<ContactUs />
			</div>
		)
	}
}
