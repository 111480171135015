import React from "react"
import PhotoGallery from "react-photo-gallery"
import Carousel, { Modal, ModalGateway } from 'react-images'

import { ImageApi } from '../../api'

export default class Gallery extends React.Component {
	state = {
		isLoading: false,
		images: [],
		modalIsOpen: false,
		selectedIndex: 0
	}

	componentDidMount = async () => {
		this.setState({ isLoading: true })

		try {
			let images = await ImageApi.getAll({
				_sort: 'createdAt:desc',
				_limit: 10
			})

			images = images.data.map(photo => photo = {
				src: process.env.SERVER_URL + photo.img.url,
				source: process.env.SERVER_URL + photo.img.url,
				width: photo.img.width,
				height: photo.img.height
			})

			this.setState({
				images,
				isLoading: false,
			})
		} catch (e) {
			console.log(e)
		}
	}

	SeletedImgHandler = (e, args) => {
		this.setState(
			{ selectedIndex: args.index },
			() => this.toggleModal()
		)
	}

	toggleModal = () => {
		this.setState(state => ({ modalIsOpen: !state.modalIsOpen }))
	};

	render() {
		return (
			<div id="gallery" className="block-holder gallery">
				<div className="block-holder__bg block-holder__bg--right"></div>
				<section className="container">
					<h2 className="container__title">Gallery</h2>
					<div className="container__content">
						{!this.state.isLoading && this.state.images && this.state.images.length > 0 &&
							<PhotoGallery
								photos={this.state.images}
								onClick={this.SeletedImgHandler} />
						}

						<ModalGateway>
							{this.state.modalIsOpen ? (
								<Modal onClose={this.toggleModal}>
									<Carousel
										views={this.state.images}
										currentIndex={this.state.selectedIndex} />
								</Modal>
							) : null}
						</ModalGateway>
					</div>
				</section>
			</div>
		)
	}
}
