import React from 'react'
import ReactDOM from 'react-dom'

export default class Expander extends React.Component {
	static defaultProps = {
		isFull: false,
    direction: 'vertical'
	}
	
	state = {
		section: undefined,
		prevExpanderState: undefined,
		filteredHeightCollapsed: undefined
	}

	currentExpanderState = undefined
	collapseAnimationId = undefined
	expandAnimationId = undefined

	componentDidMount = () => {
		window.addEventListener('resize', this.onResize);
		this.onResize()
	}

	componentWillUnmount = () => {
		this.animationCancel()
		window.removeEventListener('resize', this.onResize)
	}

	componentDidUpdate = (prevProps, prevState) => {
		if(this.currentExpanderState !== this.state.prevExpanderState) {
			this.setState({ prevExpanderState: this.currentExpanderState })
		}
	}

	onResize = () => {
		const newHeightCollapsed = this.getHeightCollapsed()

		if(this.state.filteredHeightCollapsed === newHeightCollapsed) {
			return
		}

		const node = ReactDOM.findDOMNode(this);
		const section = node.querySelector('.expander__content')
		
		this.setState({
			section,
			prevExpanderState: undefined,
			filteredHeightCollapsed: newHeightCollapsed
		})
	}

	getHeightCollapsed = () => {
		if(!this.props.breakpoints) {
			return this.props.heightCollapsed
		}

		let activeBreakpoint = null;
		const windowWidth = window.innerWidth
		const resolutions = Object.keys(this.props.breakpoints)
		resolutions.forEach(resolutionString => {
			const resolution = parseInt(resolutionString)
			
			if(windowWidth <= resolution) {
				if(!activeBreakpoint || activeBreakpoint > resolution) {
					activeBreakpoint = resolution
				}
			}
		})

		if(activeBreakpoint) {
      if(this.props.breakpoints[activeBreakpoint] && this.props.breakpoints[activeBreakpoint]['heightCollapsed']) {
        return this.props.breakpoints[activeBreakpoint]['heightCollapsed']
      }
		} else {
			return this.props.heightCollapsed
		}
	}

	collapse = () => {
		if(this.state.prevExpanderState === 'collapse') {
			return
		}

		this.currentExpanderState = 'collapse'
		this.animationCancel()

		let extraLength
		if(this.props.direction === 'vertical'){
			extraLength = Math.max(this.state.section.scrollHeight - this.state.filteredHeightCollapsed, 0)
		} else {
			extraLength = Math.max(this.state.section.scrollWidth - this.state.filteredHeightCollapsed, 0);
		}
		
		requestAnimationFrame((timestamp) => this.collapseAnimation(timestamp, timestamp, extraLength, this.props.duration))
	}
	
	collapseAnimation = (startTime, timestamp, height, duration) => {
    const runtime = timestamp - startTime
    const progress = 1 - Math.min(runtime / duration, 1)
		if(this.props.direction === 'vertical') {
			this.state.section.style.height = (height * progress + this.state.filteredHeightCollapsed) + 'px'
		} else {
			this.state.section.style.width = (height * progress + this.state.filteredHeightCollapsed) + 'px'
		}

		if (runtime < duration) {
			this.collapseAnimationId = requestAnimationFrame((timestampSub) => this.collapseAnimation(startTime, timestampSub, height, duration))
    } else {
			this.animationCancel()
		}
	}

	expand = () => {
		if(this.state.prevExpanderState === 'expand') {
			return
		}

		this.currentExpanderState = 'expand'
		this.animationCancel()

		let extraLength
		
		if(this.props.direction === 'vertical'){
			extraLength = Math.max(this.state.section.scrollHeight - this.state.filteredHeightCollapsed, 0);
		} else {
			extraLength = Math.max(this.state.section.scrollWidth - this.state.filteredHeightCollapsed, 0);
		}
		requestAnimationFrame((timestamp) => this.expandAnimation(timestamp, timestamp, extraLength, this.props.duration))
	}

	expandAnimation = (startTime, timestamp, height, duration) => {
    const runtime = timestamp - startTime
    const progress = Math.min(runtime / duration, 1)
		if(this.props.isFull) {
			if(this.props.direction === 'vertical') {
				this.state.section.style.height = (100 * progress) + '%'
			} else {
				this.state.section.style.width = (100 * progress) + '%'
			}
		} else {
			if(this.props.direction === 'vertical') {
				this.state.section.style.height = (height * progress + this.state.filteredHeightCollapsed) + 'px'
			} else {
				this.state.section.style.width = (height * progress + this.state.filteredHeightCollapsed) + 'px'
			}
		}

		if (runtime < duration) {
			this.expandAnimationId = requestAnimationFrame((timestampSub) => this.expandAnimation(startTime, timestampSub, height, duration))
    } else {
			this.animationCancel()
		}
	}

	animationCancel = () => {
		if(this.collapseAnimationId) {
			cancelAnimationFrame(this.collapseAnimationId)
			this.collapseAnimationId = undefined

			if(this.props.collapsingCompleted) {
				this.props.collapsingCompleted()
			}
		}

		if(this.expandAnimationId) {
			cancelAnimationFrame(this.expandAnimationId)
			this.expandAnimationId = undefined

			if(this.props.expandingCompleted) {
				this.props.expandingCompleted()
			}
		}
	}

	render() {
		if(this.state.section) {
			if(this.props.isExpanded) {
				this.expand()
			} else {
				this.collapse()
			}
		}

		return (
			<div className={
					'expander' +
					(!this.props.isExpanded ? ' collapsed' : '')
				}
			>
				<div className="expander__content">
					{this.props.children}
				</div>
			</div>
		)
	}
}
