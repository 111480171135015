import React from 'react'
import { Waypoint } from 'react-waypoint'

import { NewsApi } from '../../api'

import Lines from '../helpers/Lines'
import Menu from '../Menu'
import NewsArticle from './NewsArticle'
import ContactUs from '../ContactUs'
import NotFoundPage from '../NotFoundPage'

export default class NewsArticlePage extends React.Component {
	state = {
		stickMenu: false,
		newsNotFound: false,
		isLoading: false,
		newsArticle: undefined
	}

	handleStickMenuWaypointEnter = () => {
		this.setState(() => ({ stickMenu: false }))
	}

	handleStickMenuWaypointLeave = () => {
		this.setState(() => ({ stickMenu: true }))
	}

	componentDidMount = async () => {
		this.setState({ isLoading: true })

		try {
			const id = this.props.match.params.id

			const newsArticle = await NewsApi.getById(id)

			this.setState({
				newsArticle: newsArticle.data,
				isLoading: false,
			})
		} catch(e) {
			this.setState({
				newsNotFound: true,
				isLoading: false,
			})
		}
	}

	render() {
		if(this.state.newsNotFound) {
			return <NotFoundPage />
		}

		return(
			<div className="news-article-page page">
				<Lines />
				<Menu stickMenu={this.state.stickMenu} />
				<Waypoint
					onEnter={this.handleStickMenuWaypointEnter}
					onLeave={this.handleStickMenuWaypointLeave}
				/>
				{this.state.newsArticle &&
					<NewsArticle article={this.state.newsArticle} />
				}
				<div className="connection-line connection-line--last"></div>
				<ContactUs />
			</div>
		)
	}
}
