import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classname from 'classnames';

export default class Slide extends PureComponent {
  static propTypes = {
    children: PropTypes.node,
    width: PropTypes.number,
    index: PropTypes.number,
    currentSlideIndex: PropTypes.number
  };

  render() {
    const style = {
      width: `${this.props.width}px`,
      maxWidth: `${this.props.width}px`,
      minWidth: `${this.props.width}px`
    }

    return (
      <div
        className={classname(
          'BrainhubSliderItem',
          {
            'BrainhubSliderItem--active': this.props.index === this.props.currentSlideIndex,
          },
        )}
        style={style}
      >
        {this.props.children}
      </div>
    );
  }
}
