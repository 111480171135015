import React from "react";
import { Link } from "react-router-dom";
import { Waypoint } from "react-waypoint";
import {
  FaGooglePlay,
  FaItunesNote,
  FaAmazon,
  FaSpotify,
} from "react-icons/fa";

import { HeaderTextBlockApi } from "../../api";

export default class Hero extends React.Component {
  state = {
    headerTextBlock: "",
    isLoading: false,
  };

  componentDidMount = async () => {
    this.setState({ isLoading: true });

    try {
      const text = await HeaderTextBlockApi.get();

      this.setState({
        headerTextBlock: text.data.content,
      });
    } catch (e) {
      console.log(e);
    }
  };

  render() {
    return (
      <div id="hero" className="block-holder hero">
        <div className="hero__bg"></div>
        <a className="hero__email" href="mailto: contact@tommysea.com">
          contact@tommysea.com
        </a>
        <section>
          <div className="hero__text-block">
            <Waypoint
              onEnter={this.props.handleStickMenuWaypointEnter}
              onLeave={this.props.handleStickMenuWaypointLeave}
            />
            <h4>The eclectic sounds of</h4>
            <h1 className="hero__text-block__name">TOMMY SEA</h1>
            <p className="hero__text-block__description">
              {this.state.headerTextBlock}
            </p>
          </div>
        </section>
        <div className="hero__icons">
          <ul>
            <li>
              <a
                href="https://play.google.com/store/music/artist?id=Asp6nolkttofgaybguctov4nhia"
                target="_blank"
              >
                <FaGooglePlay />
              </a>
            </li>
            <li>
              <a
                href="https://open.spotify.com/artist/7GTdzNngdLM4bZUS0pMadY"
                target="_blank"
              >
                <FaSpotify />
              </a>
            </li>
            <li>
              <a
                href="https://music.apple.com/us/artist/tommy-sea/1063498337"
                alt="Apple Music"
                target="_blank"
              >
                <FaItunesNote />
              </a>
            </li>
            <li>
              <a
                href="https://music.amazon.com/search/tommy+sea"
                alt="Amazon Music"
                target="_blank"
              >
                <FaAmazon />
              </a>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}
