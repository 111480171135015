import React from 'react'

import Lines from '../helpers/Lines'
import Menu from '../Menu'
import Hero from './Hero'
import About from './About'
import Music from './Music'
import Video from './Video'
import News from './News'
import Gallery from './Gallery'
import ContactUs from '../ContactUs'

export default class MainPage extends React.Component {
	state = {
		stickMenu: true
	}

	handleStickMenuWaypointEnter = () => {
		this.setState(() => ({ stickMenu: false }))
	}

	handleStickMenuWaypointLeave = () => {
		this.setState(() => ({ stickMenu: true }))
	}

	render() {
		return(

			<div className="main-page page">
				<Lines />
				<Menu stickMenu={this.state.stickMenu} />
				<Hero
					handleStickMenuWaypointEnter={this.handleStickMenuWaypointEnter}
					handleStickMenuWaypointLeave={this.handleStickMenuWaypointLeave}
				/>
				<About />
				<div className="connection-line"></div>
				<Music />
				<div className="connection-line"></div>
				<Video />
				<div className="connection-line"></div>
				<News />
				<div className="connection-line"></div>
				<Gallery />
				<div className="connection-line"></div>
				<ContactUs isBgFilled={false} />
			</div>
		)
	}
}
