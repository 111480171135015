import React from 'react'
const uuidv4 = require('uuid/v4')
import Expander from '../helpers/Expander'
import {
	TiMediaPlayOutline
} from "react-icons/ti"
import VideoPlayer from '../VideoPlayer'

import { VideoApi } from '../../api'

// videos = [
// 	{
// 		id: uuidv4(),
// 		name: "Video 1",
// 		url: "https://www.youtube.com/watch?v=ysz5S6PUM-U",
// 		img: {
// 			url: "./video_covers/1.jpg"
// 		},
// 		isSelected: false
// 	}
// ]

export default class Video extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			selectedVideo: undefined,
			videos: undefined,
			isListHorizontal: this.isHorizontal(),
			listKey: new Date().getTime()
		}
	}

	componentDidMount = async () => {
		const videos = await VideoApi.getAll({ _sort: 'createdAt:desc' })

		if (videos.data.length > 0) {
			for (var i = 0; i < videos.data.length; i++) {
				videos[i] = {
					...videos[i],
					isSelected: false
				}
			}

			this.setState({
				selectedVideo: videos.data[0],
				videos: videos.data
			}, () => this.selectVideo(videos.data[0]))
		}

		window.addEventListener('resize', this.onResize);
	}

	componentWillUnmount = () => {
		window.removeEventListener('resize', this.onResize)
	}

	onResize = () => {
		if (!this.state.videos) return

		if (this.state.isListHorizontal !== this.isHorizontal()) {
			this.setState({
				listKey: new Date().getTime(),
				selectedVideo: this.state.videos[0],
				// videos: this.videos,
				isListHorizontal: this.isHorizontal(),
			})
		}
	}

	isHorizontal = () => window.innerWidth < 1040

	selectVideo = (video) => {
		const videos = this.state.videos.map(v => {
			if (v.id === video.id) {
				v.isSelected = true
			}

			return v
		})

		this.setState({
			videos,
			selectedVideo: video
		})
	}

	collapsingCompleted = () => {
		let hidenVideos = []
		const videos = this.state.videos.filter(v => {
			if (this.state.selectedVideo.id !== v.id && v.isSelected) {
				v.isSelected = false
				hidenVideos.push(v)
			} else {
				return v
			}
		})

		if (hidenVideos.length !== 0) {
			this.setState({
				videos: [
					...videos,
					...hidenVideos
				]
			})
		}
	}

	getDecoratedVideos = () => {
		const videos = this.state.videos.map(video => (
			<Expander
				heightCollapsed={0}
				isFull={true}
				duration={200}
				isExpanded={!video.isSelected}
				collapsingCompleted={this.collapsingCompleted}
				key={video.id}
				direction={this.state.isListHorizontal ? 'horizontal' : 'vertical'}
			>
				<a
					className="video__list__item"

					onClick={() => this.selectVideo(video)}
				>
					<img
						className="video__list__item__img"
						src={process.env.SERVER_URL + video.img.url} alt={video.name}
					/>
					<div className="video__list__item__icon">
						<TiMediaPlayOutline />
					</div>
				</a>
			</Expander>
		))

		return videos
	}

	render() {
		return (
			<div id="video" className="block-holder video">
				<div className="block-holder__bg block-holder__bg--left"></div>
				<section className="container">
					<h2 className="container__title">Video</h2>
					<div className="video__list--wrapper">
						<div className="video__list" key={this.state.listKey}>
							{this.state.videos && this.getDecoratedVideos()}
						</div>
					</div>
					<div className="container__content">
						<div className="video__player">
							{this.state.selectedVideo &&
								<VideoPlayer video={this.state.selectedVideo} />
							}
						</div>
					</div>
				</section>
			</div>
		)
	}
}
