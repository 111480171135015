import React from "react";
import { PayPalButton } from "react-paypal-button-v2";

const payPalBtn = (price, isVertical) => (
  <PayPalButton
    options={{
      clientId: process.env.PAYPAL_CLIENT_ID,
      disableFunding: "card",
    }}
    amount={price}
    style={{
      layout: isVertical ? "vertical" : "horizontal",
      color: "black",
      shape: "rect",
      label: "buynow",
      tagline: false,
    }}
    currency="USD"
    onSuccess={(details, data) => {
      alert("Transaction completed by " + details.payer.name.given_name);
    }}
    onShippingChange={(data, actions) => {
      if (data.shipping_address.country_code !== "US") {
        return actions.reject();
      }

      return actions.resolve();
    }}
  />
);

export default payPalBtn;
