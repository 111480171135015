import React from "react";
const moment = require("moment");
import { IconContext } from "react-icons";
import {
  TiMediaPlayOutline,
  TiMediaFastForwardOutline,
  TiMediaPauseOutline,
} from "react-icons/ti";

import Expander from "../helpers/Expander";
import Markdown from "../helpers/Markdown";
import payPalBtn from "../payPalBtn";

export default class Album extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tracks: this.initTracks(),
      currentTrack: undefined,
      intervalId: undefined,
      updatingTime: false,
      remainingTime: "0:00",
      progressBarWidth: 0,
    };
  }

  componentWillUnmount = () => {
    clearInterval(this.state.intervalId);
  };

  initTracks = () => {
    return this.props.album.tracks
      .sort((a, b) => a.index - b.index)
      .map((track, i) => {
        return {
          ...track,
          isLyricsExpanded:
            this.props.chosenLyrics && this.props.chosenLyrics === track._id
              ? true
              : false,
          player: new Howl({
            src: [].concat(process.env.SERVER_URL + track.file.url || []),
            format: ["mp3"],
            onplay: () => {
              const intervalId = setInterval(() => {
                if (!this.state.updatingTime && this.state.currentTrack) {
                  this.setState({
                    remainingTime: this.formatTime(
                      this.state.currentTrack.player.duration() -
                      this.state.currentTrack.player.seek()
                    ),
                    progressBarWidth: this.progressBarWidth(),
                  });
                }
              }, 50);

              this.setState(() => ({
                intervalId: intervalId,
              }));
            },
            onpause: () => {
              clearInterval(this.state.intervalId);
            },
            onstop: () => {
              this.setState({
                progressBarWidth: 0,
              });
              clearInterval(this.state.intervalId);
            },
            onend: () => {
              this.setState({
                progressBarWidth: 0,
              });
              clearInterval(this.state.intervalId);
              this.playNext(i);
            },
          }),
        };
      });
  };

  formatTime(secs) {
    secs = Math.round(secs);

    var minutes = Math.floor(secs / 60) || 0;
    var seconds = secs - minutes * 60 || 0;

    return "-" + minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
  }

  progressBarWidth = () => {
    if (this.state.currentTrack) {
      const width =
        this.state.currentTrack.player.seek() /
        (this.state.currentTrack.player.duration() / 100);

      return width;
    }

    return 0;
  };

  getDate = (date) => moment(date, moment.ISO_8601).format("MMM Do YYYY");

  createAlbum = () => (
    <div className="album-preview">
      <div className="album-preview__cover-stack">
        <div className="album-preview__img">
          <img
            src={process.env.SERVER_URL + this.props.album.coverImage.url}
            alt="Album cover"
          />
        </div>
        {this.props.album.cdPrice && this.props.album.cdPrice !== 0 && (
          <div className="album-preview__paypal">
            <h3 className="album-preview__paypal__price">
              Buy Album on CD for {this.props.album.cdPrice}$
            </h3>
            {payPalBtn(this.props.album.cdPrice, true)}
          </div>
        )}
      </div>
      <div className="album-preview__body">
        <div className="album-preview__date">
          Released: {this.getDate(this.props.album.releaseDate)}
        </div>
        <div className="album-preview__content">
          <Markdown>{this.props.album.description}</Markdown>
        </div>
      </div>
    </div>
  );

  isPlaying = (track) =>
    this.state.currentTrack && this.state.currentTrack._id === track._id;

  createTrack = (track) => (
    <div
      className={"track-preview" + (this.isPlaying(track) ? " playing" : "")}
      onClick={() => this.playStop(track)}
      key={track._id}
    >
      <div className="track-preview__header">
        <div className="track-preview__progress-bar--wrapper">
          <div
            className="track-preview__progress-bar"
            style={
              this.state.currentTrack &&
                this.state.currentTrack._id === track._id
                ? {
                  width: this.state.progressBarWidth + "%",
                }
                : { width: 0 }
            }
          ></div>
        </div>

        <h3 className="track-preview__index">
          <div className="index">{track.index}</div>
          <IconContext.Provider
            value={{ className: "player-icon player-icon--play" }}
          >
            <TiMediaPlayOutline />
          </IconContext.Provider>
          <IconContext.Provider
            value={{ className: "player-icon player-icon--pause" }}
          >
            <TiMediaPauseOutline />
          </IconContext.Provider>
        </h3>
        <h3 className="track-preview__name">{track.name}</h3>
        {track.lyrics && (
          <h3 className="track-preview__lyrics-link">
            <a onClick={(e) => this.toggleLyrics(e, track)}>Lyrics</a>
          </h3>
        )}
      </div>

      {track.lyrics && (
        <Expander
          heightCollapsed={0}
          duration={200}
          isExpanded={track.isLyricsExpanded}
        >
          <div className="track-preview__lyrics-text">
            <Markdown>{track.lyrics}</Markdown>
          </div>
        </Expander>
      )}
    </div>
  );

  toggleLyrics = (e, track) => {
    e.stopPropagation();

    let tracks = this.state.tracks;
    tracks.forEach((tr) => {
      if (tr._id === track._id) {
        tr.isLyricsExpanded = !tr.isLyricsExpanded;
      }
    });

    this.setState({ tracks });
  };

  playStop = (track) => {
    if (track && !this.state.currentTrack) {
      this.setState(
        () => ({
          currentTrack: track,
        }),
        () => track.player.play()
      );
    } else if (
      track &&
      this.state.currentTrack &&
      track._id !== this.state.currentTrack._id
    ) {
      this.state.currentTrack.player.stop();
      this.setState({ currentTrack: undefined }, () => this.playStop(track));
    } else {
      this.state.currentTrack.player.stop();
      this.setState({ currentTrack: undefined });
    }
  };

  playNext = (trackIndex) => {
    if (trackIndex >= 0 && trackIndex < this.state.tracks.length - 1) {
      this.playStop(this.state.tracks[trackIndex + 1]);
    } else {
      this.setState({
        currentTrack: undefined,
      });
    }
  };

  render() {
    return (
      <div id="album" className="block-holder album">
        <section className="container">
          <h2 className="container__title">{this.props.album.albumName}</h2>
          <div className="container__content">
            {this.createAlbum()}
            {this.state.tracks.map((track) => this.createTrack(track))}
          </div>
        </section>
      </div>
    );
  }
}
