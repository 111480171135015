import React from 'react'
import ReactPlayer from 'react-player'

export default class VideoPlayer extends React.Component {
	render() {
		return(
			<div className="video-player">
				<ReactPlayer
          className='react-player'
          url={this.props.video.url}
          width='100%'
          height='100%'
        />
			</div>
		)
	}
}
