import React from "react";
import {
  FaGooglePlay,
  FaItunesNote,
  FaAmazon,
  FaSpotify,
} from "react-icons/fa";

export default class ContactUs extends React.Component {
  static defaultProps = {
    isBgFilled: true,
  };

  render() {
    return (
      <div id="contact_us" className="block-holder contact_us">
        {this.props.isBgFilled && (
          <div className="block-holder__bg block-holder__bg--full"></div>
        )}
        <div className="contact_us__waves">
          <img src="/img/waves_vec-01.png" alt="Waves decoration" />
        </div>
        <section className="container">
          <div className="container__title__wrapper">
            <h2 className="container__title">Stay in touch</h2>
          </div>
          <div className="container__content">
            <div className="container__content__body">
              <a href="/">
                <h4 className="container__content__body__name">Tommy Sea</h4>
              </a>
              <span>|</span>
              <a href="mailto: contact@tommysea.com">contact@tommysea.com</a>
              <span>|</span>
              <span>
                <div className="container__content__icons">
                  <a
                    className="icon"
                    href="https://play.google.com/store/music/artist?id=Asp6nolkttofgaybguctov4nhia"
                    target="_blank"
                  >
                    <FaGooglePlay />
                  </a>
                  <a
                    className="icon"
                    href="https://open.spotify.com/artist/7GTdzNngdLM4bZUS0pMadY"
                    target="_blank"
                  >
                    <FaSpotify />
                  </a>
                  <a
                    className="icon"
                    href="https://music.apple.com/us/artist/tommy-sea/1063498337"
                    target="_blank"
                  >
                    <FaItunesNote />
                  </a>
                  <a
                    className="icon"
                    href="https://music.amazon.com/search/tommy+sea"
                    target="_blank"
                  >
                    <FaAmazon />
                  </a>
                </div>
              </span>
            </div>
            <div className="container__content__copyright">
              &copy; {new Date().getFullYear()} Tommy Sea Entertainment, LLC.
              All Rights Reserved.{" "}
              <a href="mailto: pavllick@gmail.com">Site by Pavlick</a>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
